@import '../variables';

.announcement-list-item {
  padding-bottom: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid lighten($dividerLine, 3%);
    margin-bottom: 14px;
  }

  .view-details-container {
    margin-top: 2px;
  }

  a.view-details-btn {
    color: #a9b4bc;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:not(:last-child) {
      margin-right: 8px;
    }

    svg {
      position: relative;
      top: -1px;
      margin-left: 2px;
    }

    &:hover {
      color: lighten($gray, 20%);
    }
  }
}