@import '../variables';

.announcements-modal {
  .modal-dialog {
    height: 90%;
  }

  .modal-content {
    height: 100%;
    min-height: 200px;
  }

  .content {
    width: 100%;
    height: 100%;
  }

  .modal-title {
    position: relative;
  }

  .back-btn-container {
    display: inline-block;
    position: absolute;

    svg {
      position: relative;
      top: -2px;
    }
  }

  .title-container {
    display: inline-block;
    position: absolute;
  }

  .empty {
    color: $gray;
  }

  .text-bubble {
    max-width: 100%;
  }
}