.outgoing-text-editor {
  padding: 10px;

  .text-editor {
    margin-bottom: 10px;
  }

  .send-btn {
    float: right;
  }

  .text-templates-dropdown {
    margin-left: 6px;
  }
}