@import '../variables';

.text {
  margin-bottom: 14px;

  > div:not(:first-child) {
    margin-top: 4px;
  }

  .timestamp, .sending, .send-error {
    font-size: 12px;
  }

  .timestamp {
    color: $gray;
  }

  .sending {
    font-style: italic;
    color: #848484;
  }

  .send-error {
    color: $red;
  }

  &.out {
    text-align: right;
  }
}