.search {
  display: inline-block;
  position: relative;

  input {
    padding: 4px 29px;
    width: 228px;
    border-radius: 8px;
    font-size: 14px;
    border: 0;
    background: #e2e2e2;
    color: #787878;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover, &:focus {
      background: #d4d4d4;
      color: #444;
    }
  }

  .search-icon, .spinner {
    position: absolute;
    left: 7px;
    top: 7px;
    opacity: 0.85;
  }

  .clear-container {
    display: inline-block;
  }

  .clear-icon {
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 7px;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;

    &:hover, &:focus {
      opacity: 0.9;
    }
  }
}