@import '../variables';

.announcement {
  .text-bubble {
    transition: background 0.35s ease-in-out;
  }

  .details {
    margin-top: 6px;
    font-size: 14px;
    color: $gray;

    .state {
      transition: color 0.35s ease-in-out;

      svg {
        margin-right: 2px;
      }
    }

    .state svg, .spinner {
      position: relative;
      top: -2px;
    }
  }

  @mixin apply($color) {
    .text-bubble {
      background: $color;
    }

    .details {
      .state, .spinner {
        color: $color;
      }
    }
  }

  &.sending {
    @include apply($green);
  }

  &.scheduled {
    @include apply($orange);
  }

  &.canceled {
    @include apply(lighten($red, 25%));
  }

  &.success {
    @include apply(darken($green, 10%));
  }

  &.partial-success {
    @include apply(lighten($green, 5%));
  }

  &.failure {
    @include apply($red);
  }
}