.text-editor {
  position: relative;

  textarea {
    height: 62px;
    width: 100%;
    resize: none;
    padding-left: 34px;
  }

  .emoji-btn {
    cursor: pointer;
    position: absolute;
    top: 5px;
    left: 6px;
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;

    &:hover, &.active {
      opacity: 0.6;
    }
  }

  .emoji-mart {
    font-family: 'Nunito Sans', sans-serif;
    position: absolute;
    bottom: 67px;
    left: 0;

    .emoji-mart-preview {
      height: 37px;

      .emoji-mart-preview-emoji, .emoji-mart-preview-data {
        display: none;
      }
    }

    .emoji-mart-category .emoji-mart-emoji:hover::before {
      display: none;
    }

    .emoji-mart-emoji, .emoji-mart-emoji span {
      cursor: pointer;
    }
  }
}