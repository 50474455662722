@import '../variables';

.announcement-detail {
  .buttons-container {
    margin: 10px 0;

    .btn:first-child:not(:last-child) {
      margin-right: 4px;
    }

    .btn:focus {
      box-shadow: none !important;
    }
  }

  .recipients-title {
    margin-top: 10px;
    border-top: 1px solid lighten($dividerLine, 3%);
    padding-top: 10px;
    margin-bottom: 6px;
  }

  .recipients {
    tr td {
      &:first-child:not(:last-child) {
        padding-right: 3px;
      }
    }

    tr:not(:last-child) td {
      padding-bottom: 4px;
    }

    svg {
      position: relative;
      top: -1px;

      &.failure {
        color: $red;
      }

      &.success {
        color: $green;
      }
    }
  }
}