@import '../variables';

.welcome-view {
  position: absolute;
  width: 100%;
  height: 100%;

  .welcome {
    font-size: 160%;
  }

  .text {
    color: #777;
    margin-bottom: 18px;
  }

  svg {
    width: 50%;
    max-width: 200px;
  }

  .logo{
    max-width: 200px;
  }

  .credits {
    color: #777;
    font-size: 95%;
    margin: 18px 0 3px 0;
  }

  .credits-third-party {
    color: #777;
    font-size: 80%;
    margin: 0;
  }

  .credits, .credits-third-party {
    a {
      color: #777;
      font-weight: 600;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: $primaryBlue;
        text-decoration: none;
      }
    }
  }
}