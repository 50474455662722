@import '../variables';

.add-announcement-modal {
  .text-editor {
    textarea {
      height: 86px;
    }

    .emoji-mart {
      left: 0;
      bottom: -400px;
      z-index: 99999;
    }
  }

  .phone-numbers {
    height: 160px;
    resize: none;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background: #fff;
    padding: 1rem;
  }

  .overlay-inner {
    width: 100%;
    height: 100%;
  }
}