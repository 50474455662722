@import '../variables';

.tooltip-button {
  display: inline-block;
}

.tooltip-button-tooltip {
  .tooltip {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}