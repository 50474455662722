@import '../variables';

.header {
  padding: 10px 12px;
  background: $primaryBlue;
  color: #fff;
  width: 100%;
  position: relative;

  .logo-container, .logo, .user, .log-out-btn {
    display: inline-block;
  }

  .logo-container {
    min-width: 115px;
    padding-right: 20px;
  }

  .logo {
    font-size: 20px;
    cursor: pointer;

    svg {
      top: -2px;
      position: relative;
      opacity: 0.5;
    }
  }

  .announcements-dropdown {
    position: absolute;
    top: 5px;
    left: 50%;
    margin-left: -79px;

    .dropdown-toggle {
      &::after {
        display: none;
      }

      background: mix($primaryBlue, #fff, 50%);
      border-color: mix($primaryBlue, #fff, 50%);
      color: $primaryBlue;

      &:hover, &:focus, &:active, &.focus, &.active {
        background: mix($primaryBlue, #fff, 40%) !important;
        border-color: mix($primaryBlue, #fff, 40%) !important;
        color: $primaryBlue !important;
      }

      > svg {
        position: absolute;
      }

      > span {
        padding-left: 15px;
      }
    }
  }

  .notifications-btn {
    float: right;
    margin-right: 12px;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;

    svg {
      position: relative;
      top: 2px;
    }

    &:not(.processing) {
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .user {
    float: right;
    margin-right: 12px;

    .name {
      font-size: 13px;
    }

    .phone-number {
      font-size: 11px;
    }
  }

  .log-out-btn {
    float: right;

    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
}

.notifications-modal {
  .modal-body {
    p:last-child {
      margin-bottom: 0;
    }

    span {
      color: $primaryBlue;
    }
  }
}