@import '../variables';

.import-contacts-modal {
  p:last-child {
    margin-bottom: 0;
  }

  .select-file-btn {
    svg {
      position: relative;
      top: -2px;
      margin-right: 4px;
    }
  }

  .table-container {
    width: 100%;
  }

  .table-container-inner {
    width: 100%;
    padding-right: 14px;
  }

  .table-content {
    height: 250px;
  }

  .table {
    margin-bottom: 0;
  }

  .phone-number {
    width: 33%;
  }

  .name {
    width: 33%;
  }

  .state {
    width: 34%;
  }

  .phone-number, .name, .state {
    word-break: break-all;
  }

  .phone-number, .name {
    .error {
      display: block;
      margin-top: 4px;
      font-size: 10px;
      color: $red;
    }
  }

  .state {
    svg {
      position: relative;
      top: -2px;
      margin-right: 4px;
    }

    .error {
      color: $red;
    }
  }

  .stats {
    position: absolute;
    left: .75rem;

    span {
      color: #545b62;

      &:not(:last-child) {
        padding-right: 8px;
        border-right: 1px solid #dee2e6;
      }

      &:not(:first-child) {
        padding-left: 8px;
      }
    }
  }
}