@import '../variables';

.contact-list {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .list-header {
    color: $primaryDarkGray;
    flex: 0 0 60px;
    padding: 0 0 10px 0;

    > .inner {
      height: 100%;
      padding: 14px 10px 0 10px;
      border-bottom: 1px solid #ced4da;
      position: relative;

      .search {
        position: relative;
        top: -2px;
      }

      > .tooltip-button {
        float: right;

        &:not(:last-child) {
          margin-left: 5px;
        }
      }
    }
  }

  .contacts-container {
    flex: 1;
    height: 100%;
    position: relative;
  }

  .list-footer {
    flex: 0 0 48px;
    padding: 9px 0 0 0;;
    text-align: center;

    a {
      padding: 5px 0;
      background: #e2e2e2;
      font-size: 13px;
      color: $primaryDarkGray;
      min-width: 89px;
      display: inline-block;
      transition: background-color 0.2s ease-in-out;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &.selected {
        background: #d4d4d4;
      }
    }
  }
}