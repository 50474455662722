@import '../variables';

.dialog-header {
  color: $primaryDarkGray;
  display: flex;
  flex-direction: row;

  .name-container {
    flex: initial;

    span {
      top: 4px;
      position: relative;
    }
  }

  .autoreply-container {
    flex: initial;

    .text-bubble {
      top: 1px;
      position: relative;
      margin-left: 9px;
      padding: 2px 6px;
      max-width: 228px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.8;
    }
  }

  .autoreply-btn-container {
    flex: 1;
  }

  .edit-btn, .autoreply-btn {
    top: 1px;
    position: relative;
    margin-left: 6px;

    svg {
      color: #d6d6d6;

      &:hover {
        color: #999;
      }
    }
  }

  .autoreply-btn {
    margin-left: 4px;
  }

  .search-container {
    flex: initial;
    text-align: right;

    .search {
      position: relative;
      margin-right: 6px;
    }
  }
}