@import 'variables';

body {
  margin: 0;
  padding: 0;
  background: $appBackground;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100% !important;
}

a {
  cursor: pointer;
}

.form-group {
  label {
    .note {
      color: #d7d7d7;
    }
  }
}

.input-error {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: $red;
}

.icon-button {
  color: #aaa;
  cursor: pointer;

  transition: color 0.25s ease-in-out;

  &:hover {
    color: #333;
  }
}