@import '../variables';

.contact-list-item {
  padding: 12px 10px;
  border-bottom: 1px solid $dividerLine;
  cursor: pointer;
  position: relative;

  .name {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .unread-texts {
    display: inline-block;
    float: right;
    padding: 0 8px;
    border-radius: 6px;
    font-weight: 600;
    background: $orange;
    color: #fff;
  }

  .phone-number {
    font-size: 13px;

    .auto-reply-badge {
      text-transform: uppercase;
      display: inline-block;
      margin-left: 6px;
      padding: 1px 3px;
      border-radius: 4px;
      background: $orange;
      color: #fff;
      font-size: 8px;
      opacity: .8;
      position: relative;
      top: -2px;
    }
  }

  svg {
    position: absolute;
    top: 22px;
    right: 15px;
    opacity: 0.3;
    display: none;
    transition: opacity 0.2s ease-in-out;

    &:not(.spinner):hover {
      opacity: 1;
    }
  }

  .spinner {
    opacity: 0.65;
  }

  .spinner, &:hover svg {
   display: inline-block;
  }

  &:not(.active):hover {
    background: darken(#f3f3f3, 5%);
  }

  &.active {
    background: $green;

    .name {
      font-weight: 600;
    }
  }

  &.has-unread-texts {
    .name {
      font-weight: 600;
    }
  }
}