.text-templates-dropdown {
  .text-template {
    width: 280px;
    overflow: hidden;
    word-break: break-all;
    display: block;
    white-space: inherit;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 36px;
    position: relative;
  }

  svg {
    position: absolute;
    top: 14px;
    right: 15px;
    opacity: 0.25;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}