@import '../variables';

.text-bubble {
  display: inline-block;
  max-width: 70%;
  text-align: left;
  padding: 8px 12px;
  border-radius: 6px;

  a {
    color: #fff;
    text-decoration: underline;
    transition: opacity .2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  &.in {
    background: darken($primaryBlue, 5%);
    color: #fff;
  }

  &.out {
    background: darken($green, 10%);
    color: #fff;
  }

  &.highlighted {
    background: $orange;
  }
}