@import '../variables';

.dialog-header-wrapper {
  flex: 0 0 60px;
  padding: 12px 10px 10px 10px;

  > .inner {
    height: 100%;
    border-bottom: 1px solid #ced4da;
  }
}

.dialog-texts-wrapper {
  flex: 1;
  height: 100%;
  position:relative;
}

.dialog-outgoing-text-wrapper {
  flex: 0 0 60px;

  .info {
    color: #777;

    span {
      background: $lightOrange;
      padding: 4px 8px;
      border-radius: 7px;
      color: #444;
      margin-right: 4px;
    }
  }
}