.text-editor-modal {
  .text-editor {
    textarea {
      height: 134px;
    }

    .emoji-mart {
      left: 0;
      bottom: -400px;
      z-index: 99999;
    }
  }

  .remove-btn {
    position: absolute;
    left: .75rem;
  }
}